.dashboardCols {
    height: calc(100vh - 57px);
    max-height: calc(100vh - 57px);

    & > div {
        overflow-y: scroll;
    }

    @media screen and (min-width: 768px) {
        height: 100vh;
        max-height: 100vh;
    }

    & > .col {
        min-width: 300px;
    }

    & > .fixedWidthCol {
        min-width: 300px;
        max-width: 300px;
    }

    & > .manageCol {
        max-width: 55px;

        & > button {
            width: 155px;
            transform: rotate(90deg);
            transform-origin: 13% 70%;
        }
    }

    & .dismiss {
        top: 5px;
        right: 0;
    }
}
