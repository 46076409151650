@import 'color-variables';

@each $key, $value in $theme-colors {
    .bg-striped-#{$key} {
        background: repeating-linear-gradient(
            45deg,
            lighten($value, 15%),
            lighten($value, 15%) 9px,
            lighten($value, 30%) 9px,
            lighten($value, 30%) 17.12px
        );
    }

    .dot-#{$key}:before {
        color: $value;
    }
}
