$color-blue-100: #112535;
    $color-blue-200: #19344E;
    $color-blue-300: #1C4367;
$color-blue-400: #31B5D1;
    $color-blue-600: #A5D3DA;

$color-gray-100: #25282A;
    $color-gray-200: #252438;
    $color-gray-300: #454555;
$color-gray-400: #5A6367;
    $color-gray-600: #DBDBD5;
    $color-gray-700: #F8F7F6;

$color-orange-200: #E05C47;
$color-orange-300: #E57153;
    $color-orange-500: #F2B681;
    $color-orange-600: #F7D3B1;

    $color-green-100: #172F35;
    $color-green-200: #2C5468;
    $color-green-300: #298890;
    $color-green-500: #4B9586;
    $color-green-700: #BADFD6;

    $color-yellow-200: #F4BB5E;
    $color-yellow-500: #EBEEC9;

$color-bronze: #AD8A56;
$color-silver: #B4B4B4;
$color-gold: #AF9500;

$theme-colors: () !default;
$theme-colors: map-merge((
    /* PRIMARY */
    "blue-100": $color-blue-100,
    "blue-400": $color-blue-400,
    "gray-100": $color-gray-100,
    "gray-400": $color-gray-400,
    "orange-500": $color-orange-500,
    "orange-600": $color-orange-600,
    /* SECONDARY */
    "blue-200": $color-blue-200,
    "blue-300": $color-blue-300,
    "blue-600": $color-blue-600,
    "gray-200": $color-gray-200,
    "gray-300": $color-gray-300,
    "gray-600": $color-gray-600,
    "gray-700": $color-gray-700,
    "orange-200": $color-orange-200,
    "orange-300": $color-orange-300,
    "green-100": $color-green-100,
    "green-200": $color-green-200,
    "green-300": $color-green-300,
    "green-500": $color-green-500,
    "green-700": $color-green-700,
    "yellow-200": $color-yellow-200,
    "yellow-500": $color-yellow-500,
    /* TIERS */
    "bronze": $color-bronze,
    "silver": $color-silver,
    "gold": $color-gold
), $theme-colors);
