@import './scss/layout';
@import './scss/colors';
@import './scss/borders';
@import './scss/bootstrap-overrides';
@import "../node_modules/bootstrap/scss/bootstrap";
@import './scss/typography';

/* GLOBAL */
::-webkit-input-placeholder { color:$color-gray-600!important; }
::-moz-placeholder { color:$color-gray-600!important; }
::-ms-placeholder { color:$color-gray-600!important; }
::placeholder { color:$color-gray-600!important; }
