@import '../../node_modules/bootstrap/scss/_functions.scss';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';

@include media-breakpoint-up(md) {
    .vh-md-100 { height: 100%; min-height:100vh; }
    .align-items-md-start { align-items: flex-start!important; }
}

.w-33 { width: 33.33%; }
.mh-100v { max-height: 100vh; }
.overflow-x-hidden { overflow-x: hidden; }
