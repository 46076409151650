@import '../../../../scss/color-variables';

.container { position: relative; }

.suggestionsContainerOpen {
    display: block;
    position: absolute;
    width: 100%;
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 6px 20px 0 rgba(0, 0, 0, 0.152);
    border: 0.5px solid #ccc;
    background-color: #fff;
    font-size: 11px;
    z-index: 2;
}

.suggestionsList {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.suggestion {
    cursor: pointer;
    padding: 10px 10px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child) {
        border-top: solid 1px $color-gray-600;
    }
}

.suggestionHighlighted { background-color: $color-gray-700; }
