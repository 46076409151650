@import 'color-variables';
@import 'typography-variables';

$body-color: $color-gray-100;

$font-family-base: $primary-font-family;

$navbar-dark-color: $color-gray-700;
$navbar-dark-hover-color: $color-orange-500;
$navbar-dark-active-color: $color-yellow-200;

$nav-tabs-link-active-bg: $color-blue-200;
$nav-tabs-link-active-color: $color-gray-700;
$nav-tabs-link-active-border-color: $color-blue-200!important;

$dropdown-link-active-bg: $color-blue-200;
$dropdown-link-active-color: $color-gray-700;

$tooltip-opacity: 1;
$tooltip-bg: $color-blue-400;
$tooltip-arrow-color: $color-blue-400;
