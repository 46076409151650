@import 'typography-variables';

@each $key, $value in $weight-classes {
    .font-weight-#{$key} {
        font-weight: $value !important;
    }
}

h1, .h1 {
    font-size: 48px;
    font-weight: $font-weight-semibold;
}

h2, .h2 {
    font-size: 36px;
    font-weight: $font-weight-regular;
}

h3, .h3 {
    font-size: 28px;
    font-weight: $font-weight-light;
}

h4, .h4 {
    font-size: 18px;
    font-weight: $font-weight-medium;
}

label {
    font-size: 13px !important;
    font-weight: $font-weight-bold;
}

.form-check-label {
    font-weight: $font-weight-regular;
}

p, .p {
    font-size: 16px;
    font-weight: $font-weight-regular;
}

.small {
    font-size: 13px;
    font-weight: $font-weight-regular;
}

.smaller {
    font-size: 11px;
    font-weight: $font-weight-light;
}
