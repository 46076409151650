$enable-responsive-font-sizes: true;

$primary-font-family:   'Montserrat', sans-serif;
$font-weight-light:     300;
$font-weight-regular:   400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;

$weight-classes: () !default;
$weight-classes: map-merge((
    light: $font-weight-light,
    regular: $font-weight-regular,
    medium: $font-weight-medium,
    semibold: $font-weight-semibold,
    bold: $font-weight-bold
), $weight-classes);
